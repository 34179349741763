'use client';

import { ArrowForward } from '@mui/icons-material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import {
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  LinearProgress,
  Tabs,
  Typography,
} from '@mui/material';
import { type Gallery } from '@ocode/domain';
import { ApiRequestContext } from '@ocodelib/api-common';
import { FlexRow, StackRow, sxDialogHeight, TopAbsolute } from '@ocodelib/mui';
import { useLoadingVisible } from '@ocodelib/mui/hooks';
import { CustomDialogTitle } from '@repo-foundation/components/CustomDialogTitle';
import { useLocale } from 'next-intl';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { CountWithReplyIcon } from '../../components/count-with-icon/CountWithReplyIcon';
import { CustomDialog } from '../../components/CustomDialog';
import { GalleryChimButton } from '../../components/GalleryChimButton';
import { GalleryFavorButton } from '../../components/GalleryFavorButton';
import { PageContentTab } from '../../components/PageContentTab';
import { GalleryAuthorView } from '../../gallery/GalleryAuthorView';
import { GalleryIntroView } from '../../gallery/GalleryIntroView';
import { GalleryPreviewPlayer } from '../../gallery/GalleryPreviewPlayer';
import { useRouterI18n } from '../../i18n/routing';
import { useApi } from '../../provider/useApi';
import { routerPushI18n } from '../../router';
import { useLoginUser } from '../../store/useAuthStore';
import { handleError } from '../../util/handle-error';

const TABS = [
  { key: 'preview', label: '미리보기' },
  { key: 'intro', label: '작품설명' },
  { key: 'author', label: '작성자' },
] as const;

type TabKey = (typeof TABS)[number]['key'];

export interface GalleryPreviewDialogProps {
  open: boolean;

  /**
   * 갤러리 ID
   */
  galleryId: number;

  /**
   * 다이얼로그 닫기 콜백,
   * 찜,좋아요를 누르면 카운트가 변경되는데
   * 이러한 변경이 있는 경우, 다이얼로그가 닫힐 때 changedGallery가 전달됩니다.
   */
  onClose: (changedGallery?: Gallery) => void;

  /**
   * 다이얼로그에서 갤러리를 삭제한 경우 호출되는 콜백
   */
  onDeleted?: () => void;
}

export function GalleryPreviewDialog(props: GalleryPreviewDialogProps) {
  const { open, onClose, galleryId, onDeleted } = props;
  const locale = useLocale();
  const api = useApi();
  const [loading, setLoading] = useState(true);
  const loadingVisible = useLoadingVisible(loading);
  const [gallery, setGallery] = useState<Gallery>();
  const [largeScreen, setLargeScreen] = useState(false);
  const [tabKey, setTabKey] = useState<TabKey>('preview');
  const { profile, isAdmin } = useLoginUser();
  const isChangedRef = useRef(false);
  const router = useRouterI18n();

  const handleClose = () => {
    onClose(isChangedRef.current ? gallery : undefined);
  };

  const doLoad = useCallback(
    async (ctx: ApiRequestContext, galleryId: number) => {
      try {
        setLoading(true);
        const { gallery } = await api.gallery.info({ ctx, galleryId });
        if (ctx.canceled) return;
        setGallery(gallery);
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    },
    [api],
  );

  useEffect(() => {
    const ctx = ApiRequestContext.of();
    doLoad(ctx, galleryId);
    return () => {
      ctx.cancel();
    };
  }, [doLoad, galleryId]);

  // 좋아요 여부 변경됨
  const handleFavorCountChanged = (galleryId: number, count: number, isFavor: boolean) => {
    if (!gallery) return;
    const newGallery = {
      ...gallery,
      favorCount: count,
      isFavor,
    };
    setGallery(newGallery);
    isChangedRef.current = true;
  };

  // 찜 여부 변경됨
  const handleChimCountChanged = (galleryId: number, count: number, isChim: boolean) => {
    if (!gallery) return;
    const newGallery = {
      ...gallery,
      chimCount: count,
      isChim,
    };
    setGallery(newGallery);
    isChangedRef.current = true;
  };

  const doDelete = useCallback(
    async (galleryId: number): Promise<boolean> => {
      try {
        await api.gallery.delete({ galleryId });
        return true;
      } catch (err) {
        handleError(err);
      }
      return false;
    },
    [api],
  );

  // 삭제 버튼 클릭
  const handleClickDeleteBtn = () => {
    if (!gallery) return;
    if (!confirm('삭제하시겠습니까?')) return;
    doDelete(galleryId).then((succ) => {
      if (succ) {
        toast.success('삭제되었습니다');
        onDeleted?.();
        onClose();
      }
    });
  };

  const isOwner = Boolean(profile && profile.accountId === gallery?.accountId);

  return (
    <CustomDialog
      className="GalleryPreviewDialog-root"
      open={open}
      maxWidth={largeScreen ? 'lg' : 'md'}
      fullWidth
      onClose={handleClose}
      sx={{
        ...sxDialogHeight('height', largeScreen ? 100 : 85),
      }}
    >
      <CustomDialogTitle
        onClose={handleClose}
        sx={{
          borderBottom: '1px solid #ddd',
        }}
      >
        <FlexRow alignItems="center">
          <IconButton
            size="small"
            onClick={() => {
              setLargeScreen((p) => !p);
            }}
          >
            {largeScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
          <Tabs
            value={tabKey}
            sx={{
              '& .MuiTabs-indicator': {
                // border: '1px solid #000',
              },
              // '& .MuiButtonBase-root': {
              //   color: '#000',
              // },
            }}
            onChange={(_, value) => {
              setTabKey(value);
            }}
          >
            {TABS.map(({ key, label }) => (
              <PageContentTab key={key} label={label} value={key} />
            ))}
          </Tabs>
        </FlexRow>
      </CustomDialogTitle>
      <Typography variant="h6" sx={{ textAlign: 'center', mt: 2, mb: 2 }}>
        {gallery?.title}
      </Typography>

      <DialogContent
        dividers
        sx={{
          py: 0,
          px: 0,
          display: 'flex',
          flexDirection: 'column',
          borderTop: 'none',
        }}
      >
        {gallery && tabKey === 'preview' && (
          <GalleryPreviewPlayer
            gallery={gallery}
            sx={{
              flex: 1,
            }}
          />
        )}
        {gallery && tabKey === 'intro' && (
          <GalleryIntroView
            gallery={gallery}
            sx={{
              flex: 1,
              p: 2,
            }}
          />
        )}

        {gallery && tabKey === 'author' && (
          <GalleryAuthorView
            author={gallery.author}
            breed={gallery.breed}
            onClickGallery={() => {
              routerPushI18n(`/gallery/view`, gallery.galleryId);
              onClose();
            }}
            sx={{
              flex: 1,
              pt: 2,
              px: {
                xs: 1,
                sm: 2,
                md: 3,
                lg: 4,
              },
            }}
          />
        )}

        {loadingVisible && (
          <TopAbsolute>
            <LinearProgress color="secondary" />
          </TopAbsolute>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', p: 1.5 }}>
        <StackRow alignItems="center" spacing={1}>
          {(isOwner || isAdmin) && (
            <Button variant="outlined" size="small" onClick={handleClickDeleteBtn}>
              삭제
            </Button>
          )}

          {gallery && (
            <GalleryFavorButton
              count={gallery.favorCount}
              active={gallery.isFavor}
              galleryId={gallery.galleryId}
              addApiFn={api.gallery.favorAdd}
              removeApiFn={api.gallery.favorRemove}
              onChangeValue={handleFavorCountChanged}
              sx={{ border: '1px solid rgba(0, 172, 255, 0.5)' }}
            />
          )}
          {gallery && (
            <GalleryChimButton
              count={gallery.chimCount}
              active={gallery.isChim}
              galleryId={gallery.galleryId}
              addApiFn={api.gallery.chimAdd}
              removeApiFn={api.gallery.chimRemove}
              onChangeValue={handleChimCountChanged}
              sx={{ border: '1px solid rgba(0, 172, 255, 0.5)' }}
            />
          )}
          {gallery && <CountWithReplyIcon count={gallery.replyCount} />}
        </StackRow>
        <Button
          onClick={() => {
            handleClose();
            router.push(`/gallery/view/${galleryId}`);
          }}
          variant="contained"
          endIcon={<ArrowForward />}
          sx={{
            px: { xs: 1, md: 3 },
          }}
        >
          자세히
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}
